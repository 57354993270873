import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Grid,
  Modal,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/App/hooks";
import {
  GetChapterExamQuestion,
  isNavigatingFromChapterExamF,
  isNavigatingFromChapterExamT,
  setResultPage,
  SubmitChapterQuestionsAns,
} from "Redux/feature/ChapterExamSlice";
import {
  GetAllSubmittedQuestion,
  setNumericBar,
  setPreviewName,
  setProgressBarFlag,
  setQuestionBar,
  setQuizQuestionComplete,
  setQuizColorChange,
  setResetAss,
  setStartIndex,
  setFetchAllSubmittedQuestions,
  updateActiveQuestion,
} from "Redux/feature/Questions";
import AccordionForChapterExam from "views/common/AccordionForChapterExam";
import AccordionForChapterPrinciple from "views/common/AccordionForChapterPrinciple";
import QuestionAttemptAndCheck from "../QuestionTypes/QuestionAttemptAndCheck";
import DescriptionBox from "../DescriptionBox";
import QuestionSideBar from "../Sidebar/QuestionSideBar";
import { ReactComponent as EditIconCollaps } from "../../../assets/images/editIconCollaps.svg";
import { ReactComponent as EditIconCollapsModel } from "../../../assets/images/EditIconCollapsModel.svg";
import { ReactComponent as ArrowRight } from "../../../assets/images/ArrowRight.svg";
import {
  CompleteContainerAtomic,
  FetchAtomicAndContainerData,
  GetNextAssignment,
  setAssignmentData,
  setIconSidebar,
  setOpenAssignmentSuccess,
  setOpenSessionSuccess,
  setSideBar,
  setActiveIndex,
} from "Redux/feature/StudentHome";
import { ResetQuestionsState } from "Redux/feature/Questions";
import AssignmentDialog from "views/common/AssignmentDialog";
import SessionDialog from "views/common/SessionDialog";
import { getCookie } from "views/common/cookieUtils";

type QuestionSelection = {
  chapter_id: string;
  questionIndex: number;
  correct_answer: string;
  option_id: string; // Include option_id in the type definition
};

const ChapterExam = () => {
  const mainBoxStyle = {
    marginY: "2%",
    mr: { xs: "-20px", md: "0" },
    mb: { xs: "30px", md: "0" },
    padding: { xs: "0", md: "20px" },
    gap: "5%",
    borderRadius: { xs: "0", md: "20px" },
    border: { xs: "0", md: "1px solid var(--black-black-40, #DFE2E5)" },
    backgroundColor: { xs: "transparent", md: "#FFF" },
    boxShadow: { xs: "none", md: "0px 5px 16px 0px rgba(8, 15, 52, 0.05)" },
    overflow: "auto",
  };

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isDialogReset, setIsDialogReset] = useState<boolean>(false);
  const [checkResultPage, setCheckResultPage] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [showResultMode, setShowResultMode] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [customValues, setCustomValues] = useState<{ [key: string]: number }>(
    {}
  );
  const [option_id, setOptionId] = useState<any>();

  const pages = ["subjects", "transverse-principles"];

  const user_id = getCookie("id");
  const assignment_id = searchParams.get("assignment_id");
  const is_student_exam = searchParams.get("is_student_exam");
  const product_exam = searchParams.get("product_exam");
  const containerId = searchParams.get("container_type_id");
  const isSmallScreen = useMediaQuery("(min-width: 900px)");
  const { isLoading }: any = useAppSelector((state) => state.ChapterExamSlice);

  const assignmentModal: boolean = useAppSelector(
    (state) => state.StudentsHomeData.openAssignmentSuccess
  );
  const sessionModal: boolean = useAppSelector(
    (state) => state.StudentsHomeData.openSessionSuccess
  );
  const resetAss = useAppSelector((state) => state.Questions.resetAss);
  const resultPage: boolean = useAppSelector(
    (state) => state.ChapterExamSlice.resultPage
  );
  const previewName = useAppSelector((state) => state.Questions.previewName);
  const assignmentDetail = JSON.parse(localStorage.getItem("assignment") || "[]")

  const setPrincipleAccordionNavigationFlag: boolean = useAppSelector(
    (state) => state.ChapterExamSlice.getPrincipleAccordionNavigationFlag
  );
  const selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  const ChapterExamSlice: any = useAppSelector(
    (state) => state?.ChapterExamSlice?.getChapterExamQuestion
  );
  let atomicUnitData: any = useAppSelector(
    (state) => state.StudentsHomeData.atomicUnitsData
  );
  const assignmentData: any = localStorage.getItem("assignment");
  const assignment: any = JSON.parse(assignmentData);

  const atomicIndex = useAppSelector(
    (state) => state.StudentsHomeData.activeAtomicIndex
  );
  const selectedChapter: Number = useAppSelector(
    (state) => state?.ChapterExamSlice?.selectedChapter
  );
  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );
  const fetchAllSubmittedQuestionAnswer: any = useAppSelector(
    (state) => state.Questions.fetchAllSubmittedQuestions
  );
  const isResearch = location?.state?.isResearch;

  const currentQuestion = activeQuestion + 1;
  const defaultCourseId = getCookie("default_course_id");
  const ChapterWiseQuestion = ChapterExamSlice?.chapter
    ?.filter((chapter: any) => chapter.chapter_id == selectedChapter)
    .map((chapter: any) => chapter.questions);
  const assignmentId = ChapterExamSlice?.assignment?.[0].id;

  const sections =
    ChapterExamSlice?.chapter?.map((sectionData: any, index: number) => {
      const questionsLength = Array.from(
        { length: sectionData?.questions?.length },
        (_, index) => index
      );

      const questionsWithOptions = sectionData?.questions.map(
        (question: any) => {
          const options = question?.option?.map((option: any) => option.text);
          return {
            ...question,
            options: options || [],
          };
        }
      );

      return {
        title: "section",
        sideTitle: "פרק",
        height: "19%",
        column: index < 5 ? 1 : 2,
        row: index < 5 ? index + 1 : index - 2,
        chapter_id: sectionData?.chapter_id,
        questionsLength: questionsLength,
        questions: questionsWithOptions,
      };
    }) || [];

  const [selectedOptions, setSelectedOptions] = useState<{
    [questionId: string]: string;
  }>({});

  const [selectedQuestions, setSelectedQuestions] = useState<
    QuestionSelection[]
  >(
    sections
      .filter((section: any) => section.chapter_id !== 0)
      .flatMap((section: any) =>
        section?.questions.map((_: any, questionIndex: number) => ({
          chapter_id: section?.chapter_id,
          questionIndex,
          correct_answer: "",
          option_id: "", // Initialize option_id as an empty string
        }))
      )
  );

  useEffect(() => {
    const currentIndex = assignment?.findIndex((item: any) => {
      return (
        item.types === "container" &&
        item.atomic_container_id == searchParams.get("container_id")
      );
    });
    dispatch(setActiveIndex(currentIndex));
  }, [searchParams.get("container_id")]);

  useEffect(() => {
    if (!isResearch) {
      setIsDialogOpen(false);
      setIsDialogReset(false);
      setCheckResultPage(false);
      setCurrentTab(0);
      setShowResultMode(false);
      setIsModalOpen(false);
      setCustomValues({});
      setSelectedOptions({});
      setSelectedQuestions([]);
      dispatch(setResultPage(false));
      dispatch(setProgressBarFlag(false));
      dispatch(setNumericBar(false));
      dispatch(setQuizQuestionComplete(false));
      dispatch(setQuizColorChange(false));
      dispatch(setSideBar([]));
      dispatch(setFetchAllSubmittedQuestions([]));
      dispatch(updateActiveQuestion(0));
      dispatch(setQuestionBar(false));
    }
    dispatch(
      GetChapterExamQuestion({
        user_id: user_id ? user_id : null,
        assignment_id: assignment_id,
      })
    ).then((res) => {
      dispatch(setPreviewName(res?.payload?.data?.assignment[0]?.name));
      const isCompleted = res?.payload?.data?.is_complete;
      if (isCompleted === 1) {
        dispatch(setResultPage(true));
      }
    });
  }, [user_id, isResearch]);

  useEffect(() => {
    const pageSize = isSmallScreen ? 10 : 5;

    const calculateStartIndex = (question: any) =>
      Math.floor(question / pageSize) * pageSize;

    let nextStartIndex;

    if (!isSmallScreen) {
      nextStartIndex = calculateStartIndex(activeQuestion);
    } else {
      nextStartIndex = calculateStartIndex(activeQuestion);
    }

    dispatch(setStartIndex(nextStartIndex));
  }, [activeQuestion, isSmallScreen]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleClosePopup = () => {
    setIsDialogOpen(false);
  };

  const handleAnswerSubmit = () => {
    const hasUnattemptedQuestions = allQuestions.some(
      (question) => question.correct_answer === ""
    );

    if (hasUnattemptedQuestions) {
      setIsDialogOpen(true);
      setCheckResultPage(false);
    } else {
      setIsDialogOpen(false);
      setCheckResultPage(false);
      dispatch(
        SubmitChapterQuestionsAns({
          user_id: user_id ? user_id : null,
          assignment_id: assignmentId,
          type: "Exam",
          data: allQuestions,
        })
      ).then(() => {
        dispatch(setResultPage(true));
        setIsDialogOpen(false);
        setCheckResultPage(false);
        setCustomValues({});
        setSelectedQuestions([]);
        setSelectedOptions({});
      });
    }
  };

  const nextPage = () => {
    setIsDialogOpen(false);
    setCheckResultPage(false);
    dispatch(setResultPage(true));
    dispatch(isNavigatingFromChapterExamF(true));
    if (user_id) {
      dispatch(
        SubmitChapterQuestionsAns({
          user_id: user_id,
          assignment_id: assignmentId,
          type: "Exam",
          data: allQuestions,
        })
      ).then((res) => {
        if (res.payload.status === true) {
          setCustomValues({});
          setSelectedQuestions([]);
          setSelectedOptions({});
          if (res.payload.data.data.length > 0)
            dispatch(isNavigatingFromChapterExamT(true));
        }
      });
    }
    const atomicContainerId = containerId;
    if(!is_student_exam){

    const currentIndex = assignment?.findIndex((item: any) => {
      return (
        item.types === "container" &&
        item?.container[0]?.container_type_id == atomicContainerId
      );
    });
    console.log(currentIndex,"currentIndex")
    let currentElement: any = assignment[currentIndex];
    if (currentElement && user_id) {
      const meetingIndex = atomicUnitData?.findIndex((item: any) => {
        return item.meeting.assignment.some(
          (assignmentData: any) => assignmentData.id == assignment_id
        );
      });
      dispatch(
        CompleteContainerAtomic({
          student_id: user_id,
          assignment_id: currentElement?.assignment_id,
          meeting_id: localStorage.getItem("meetingId")
            ? localStorage.getItem("meetingId")
            : atomicUnitData[meetingIndex]?.meeting?.id,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          type: "exam",
          id: currentElement?.atomic_container_id,
        })
      ).then(() => {
        dispatch(
          FetchAtomicAndContainerData({
            id: user_id,
            course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          })
        ).then((resp: any) => {
          atomicUnitData = resp.payload.data;
          handleAssignmentData();
        });
      });
    }
    }
  };

  const handleOptionClick = (
    sectionIndex: any,
    questionIndex: any,
    optionIndex: any
  ) => {
    const chapter_id = sections[sectionIndex]?.chapter_id;
    const selectedOptionKey = `${sectionIndex}_${questionIndex}`;
    const updatedQuestionIndex = questionIndex;
    const selectedOptionValue = optionIndex;
    const optionID =
      sections[sectionIndex]?.questions[questionIndex]?.option[
        selectedOptionValue
      ]?.id;
    const questionId =
      sections[sectionIndex]?.questions[questionIndex]?.option[
        selectedOptionValue
      ]?.question_id;

    const isOptionSelected =
      parseInt(selectedOptions[selectedOptionKey]) === optionIndex + 1;

    if (isOptionSelected) {
      setSelectedOptions((prevSelectedOptions) => {
        const updatedOptions = { ...prevSelectedOptions };
        delete updatedOptions[selectedOptionKey];
        return updatedOptions;
      });
      setCustomValues((prevCustomValues) => {
        const updatedValues = { ...prevCustomValues };
        delete updatedValues[selectedOptionKey];
        return updatedValues;
      });
    } else {
      setSelectedOptions((prevSelectedOptions) => ({
        ...prevSelectedOptions,
        [selectedOptionKey]: selectedOptionValue + 1,
      }));
      setCustomValues((prevCustomValues) => ({
        ...prevCustomValues,
        [selectedOptionKey]: selectedOptionValue + 1,
      }));
    }

    if (chapter_id !== 0) {
      setSelectedQuestions((prevSelectedQuestions) => {
        const selectedQuestionIndex = prevSelectedQuestions.findIndex(
          (question) =>
            question?.chapter_id === chapter_id &&
            question?.questionIndex === updatedQuestionIndex
        );
        if (selectedQuestionIndex === -1) {
          return [
            ...prevSelectedQuestions,
            {
              chapter_id,
              questionIndex: updatedQuestionIndex,
              correct_answer: optionID,
              option_id: optionID,
              question_id: questionId === undefined ? 0 : questionId,
            },
          ];
        } else {
          const updatedQuestions = [...prevSelectedQuestions];
          updatedQuestions[selectedQuestionIndex] = {
            ...updatedQuestions[selectedQuestionIndex],
            correct_answer: isOptionSelected ? "" : optionID,
            option_id: optionID,
          };
          return updatedQuestions;
        }
      });
    }
  };

  const nonAttemptedQuestions = sections.flatMap((section: any) => {
    return section.questions
      .filter(
        (_: any, questionIndex: number) =>
          !selectedQuestions.some(
            (selectedQuestion) =>
              selectedQuestion.chapter_id === section.chapter_id &&
              selectedQuestion.questionIndex === questionIndex
          )
      )
      .map((question: any, questionIndex: number) => {
        if (question?.option && question?.option.length > 0) {
          const chapter_id = section.chapter_id;
          const updatedQuestionIndex = questionIndex;
          const questionId = question.option[0].question_id;
          const optionID = question.option[0].id;

          if (chapter_id !== 0) {
            return {
              chapter_id,
              questionIndex: updatedQuestionIndex,
              correct_answer: "",
              option_id: optionID,
              question_id: questionId ? questionId : 0,
            };
          }
        }
      });
  });

  const allQuestions = [
    ...selectedQuestions,
    ...nonAttemptedQuestions.filter(Boolean),
  ];

  const handleResetClick = () => {
    setSelectedOptions({});
    setSelectedQuestions([]);
    setCustomValues((prevCustomValues) => {
      const updatedCustomValues = { ...prevCustomValues };
      Object.keys(updatedCustomValues).forEach((key) => {
        updatedCustomValues[key] = 0;
      });
      return updatedCustomValues;
    });
    setIsDialogReset(false);
  };
  const handleResetExam = () => {
    setIsDialogReset(true);
  };
  const handleReset = () => {
    setIsDialogReset(false);
  };
  const handleCheckResultExam = () => {
    dispatch(
      GetAllSubmittedQuestion({
        user_id: user_id,
        assignment_id: assignmentId,
      })
    ).then((resp: any) => {
      if (resp.payload.status === true && resp.payload.code === 200) {
        setCheckResultPage(true);
        dispatch(setProgressBarFlag(true));
        dispatch(setNumericBar(true));
        dispatch(setQuizQuestionComplete(true));
      }
    });
  };
  const handleNextCheckAnswer = () => {
    if (showResultMode === false) {
      setShowResultMode(true);
      dispatch(updateActiveQuestion(0));
    }
    if (activeQuestion === fetchAllSubmittedQuestionAnswer.length - 1) {
      dispatch(setQuizQuestionComplete(true));
      dispatch(setQuizColorChange(true));
    } else {
      dispatch(updateActiveQuestion(activeQuestion + 1));
    }
  };
  const nextAssignment = () => {
    const meetingIndex = atomicUnitData.findIndex((item: any) => {
      return item.meeting.assignment.some(
        (assignmentData: any) => assignmentData.id == assignment_id
      );
    });
    if (user_id) {
      dispatch(
        GetNextAssignment({
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          meeting_id: localStorage.getItem("meetingId")
            ? localStorage.getItem("meetingId")
            : atomicUnitData[meetingIndex]?.meeting?.id,
          assignment_id: assignment_id,
          student_id: user_id,
        })
      ).then((resp: any) => {
        if (resp.payload.data.length == 0) {
          if (resp.payload.meeting_completed == 1) {
            dispatch(setOpenSessionSuccess(true));
          }
          navigate("/student-home");
        } else {
          if (resp.payload.meeting_completed == 1) {
            dispatch(setOpenSessionSuccess(true));
          } else {
            dispatch(setOpenAssignmentSuccess(true));
          }
          var jsonString = JSON.stringify(resp.payload.data.atomics_containers);
          localStorage.setItem("assignment", jsonString);
          const meetingData = JSON.stringify(
            resp.payload.data.meeting_assignment.meeting_id
          );
          localStorage.setItem("meetingId", meetingData);

          dispatch(setAssignmentData(resp.payload.data.atomics_containers));

          const indexFirstNotCompleted =
            resp.payload.data.atomics_containers?.findIndex(
              (item: { is_completed: 0 }) => item.is_completed == 0
            );
          dispatch(setActiveIndex(indexFirstNotCompleted));

          const firstNotCompletedItem =
            resp.payload.data.atomics_containers[indexFirstNotCompleted];

          if (firstNotCompletedItem?.types == "container") {
            const examTime =
              firstNotCompletedItem?.container[0]?.container_duration;

            const containerUrl = `/question-pages?container_type_id=${firstNotCompletedItem?.container[0]?.container_type_id}&container_id=${firstNotCompletedItem?.atomic_container_id}&assignment_id=${firstNotCompletedItem?.assignment_id}`;
            const state = { examTime };
            navigate(containerUrl, { state });
          } else {
            const atomicType = firstNotCompletedItem?.atomic[0]?.types.name;
            const assignmentId = firstNotCompletedItem?.assignment_id;
            const atomic_id = firstNotCompletedItem?.atomic_container_id;
            const link = firstNotCompletedItem?.atomic[0]?.link;

            const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;

            const state = { link };
            navigate(url, { state });
          }
        }
      });
    }
  };
  const handleAssignmentData = () => {
    let arr: any = [];
    let prevContainerTypeId: number | null = null;
    if(searchParams.get("product_exam")){
      if (assignmentDetail?.length > 0) {
        console.log(assignmentDetail,"assignmentdata")
        assignmentDetail?.forEach((data: any) => {
          
            if (
              data.types === "container" &&
              data.container[0]?.container_type_id == 5
            ) {
              if (prevContainerTypeId != 5) {
                arr.push(data);
                prevContainerTypeId = 5;
              }
            } else {
              arr.push(data);
              prevContainerTypeId = null;
            }
        });
      }
    }else{
      atomicUnitData?.forEach((atomicData: any) => {
        if (atomicData?.meeting?.assignment?.length > 0) {
          atomicData.meeting?.assignment?.forEach((item: any) => {
            if (item.atomics_containers?.length > 0) {
              item.atomics_containers?.forEach((data: any) => {
                if (data?.assignment_id == assignment_id) {
                  const matchingObject = atomicData?.meeting?.assignment.find(
                    (test: any) => test.id == assignment_id
                  );
                  const assignment_name = matchingObject.preview_name;
                  if (matchingObject?.progress == 100) {
                    dispatch(setIconSidebar(matchingObject?.icon?.icon1));
                  } else {
                    dispatch(setIconSidebar(matchingObject?.icon?.icon2));
                  }
                  dispatch(setPreviewName(assignment_name));
                  if (
                    data.types === "container" &&
                    data.container[0]?.container_type_id == 5
                  ) {
                    if (prevContainerTypeId != 5) {
                      arr.push(data);
                      prevContainerTypeId = 5;
                    }
                  } else {
                    arr.push(data);
                    prevContainerTypeId = null;
                  }
                }
              });
            }
          });
        }
      });
    }

    

    dispatch(setSideBar(arr));
  };
  const handleNavigateResearch = () => {
    if(product_exam){
      navigate("/home/learning-management/exam-analysis/add-test");

    }else{

    navigate("/home/learning-management/");
    }
  };
  const handleComplete = () => {
    if (!isResearch) {
      setIsDialogOpen(false);
      setIsDialogReset(false);
      setCheckResultPage(false);
      setCurrentTab(0);
      setShowResultMode(false);
      setIsModalOpen(false);
      setCustomValues({});
      setSelectedOptions({});
      setSelectedQuestions([]);
      dispatch(setResultPage(false));
      dispatch(setProgressBarFlag(false));
      dispatch(setNumericBar(false));
      dispatch(setQuizQuestionComplete(false));
      dispatch(setQuizColorChange(false));
      dispatch(setSideBar([]));
      dispatch(setOpenAssignmentSuccess(false));
      dispatch(setOpenSessionSuccess(false));
      dispatch(setFetchAllSubmittedQuestions([]));
      dispatch(updateActiveQuestion(0));
      dispatch(setQuestionBar(false));
    }

    assignment?.map((item: any, index: any) => {
      return null;
    });
    const currentIndex = assignment?.findIndex((item: any) => {
      return (
        item.types === "container" &&
        item.atomic_container_id == searchParams.get("container_id")
      );
    });
    dispatch(setActiveIndex(currentIndex));
    let currentElement = assignment[currentIndex];
    const meetingIndex = atomicUnitData?.findIndex((item: any) => {
      return item.meeting.assignment.some(
        (assignmentData: any) => assignmentData.id == assignment_id
      );
    });
    if (user_id) {
      dispatch(
        CompleteContainerAtomic({
          student_id: user_id,
          assignment_id: currentElement?.assignment_id,
          meeting_id: localStorage.getItem("meetingId")
            ? localStorage.getItem("meetingId")
            : atomicUnitData[meetingIndex]?.meeting?.id,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          type: "exam",
          id: currentElement?.atomic_container_id,
        })
      ).then(() => {
        dispatch(
          FetchAtomicAndContainerData({
            id: user_id,
            course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          })
        ).then((resp: any) => {
          atomicUnitData = resp.payload.data;
          handleAssignmentData();
        });
      });
    }
    dispatch(setResultPage(false));
    handleResetClick();
    if (assignment.length - 1 === atomicIndex) {
      dispatch(ResetQuestionsState());
      dispatch(setSideBar([]));
      dispatch(setActiveIndex(0));
      if (resetAss) {
        navigate("/student-home");
        dispatch(setResetAss(false));
      } else {
        nextAssignment();
      }
    } else {
      if (currentIndex >= 0 && currentIndex < assignment.length - 1) {
        const nextElement = assignment[currentIndex + 1];
        if (nextElement.types == "container") {
          dispatch(setQuizQuestionComplete(false));
          dispatch(setFetchAllSubmittedQuestions([]));
          dispatch(updateActiveQuestion(0));
          if (nextElement?.container[0]?.container_type_id == "1") {
            dispatch(setQuestionBar(false));
          }
          if (nextElement?.container[0]?.container_type_id == "6") {
            dispatch(setProgressBarFlag(false));
          }
          if (nextElement?.container[0]?.container_type_id == "5") {
            if (resetAss) {
              navigate("/student-home");
              dispatch(setResetAss(false));
            } else {
              nextAssignment();
            }
          } else {
            dispatch(setQuizQuestionComplete(false));
            const examTime = nextElement?.container[0]?.container_duration;

            const containerUrl = `/question-pages?container_type_id=${nextElement.container[0]?.container_type_id}&container_id=${nextElement?.atomic_container_id}&assignment_id=${nextElement?.assignment_id}`;

            const state = { examTime };
            navigate(containerUrl, { state });
          }
        } else if (nextElement.types == "atomic") {
          const atomicType = nextElement?.atomic[0]?.types.name;
          const assignmentId = nextElement?.assignment_id;
          const atomic_id = nextElement?.atomic_container_id;
          const link = nextElement?.atomic[0]?.link;

          const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;

          const state = { link };
          navigate(url, { state });
        }
      } else {
        navigate("/student-home");
      }
    }
    dispatch(setActiveIndex(0));
    dispatch(setSideBar([]));
  };
  const handleSelectOption = (e: any) => {
    setOptionId(e);
  };
  const handleIconCClick = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const validateInput = (input: any) => {
    return /^[0-4]$/.test(input) ? input : "";
  };

  const handleCustomValueChange = (
    event: any,
    sectionIndex: any,
    questionIndex: any
  ) => {
    const [section, question] = event.target.name
      .split("-")
      .slice(1)
      .map(Number);

    const selectedOptionKey = `${section}_${question}`;
    const validatedValue = validateInput(event.target.value);
    const { maxLength, value } = event.target;

    const chapter_id = sections[sectionIndex]?.chapter_id;
    const updatedQuestionIndex = questionIndex;
    const selectedOptionValue = parseInt(validatedValue);

    const optionID =
      sections[sectionIndex]?.questions[questionIndex]?.option[
        selectedOptionValue === 0
          ? ""
          : selectedOptionValue === 1
          ? 0
          : selectedOptionValue - 1
      ]?.id;
    const questionId =
      sections[sectionIndex]?.questions[questionIndex]?.option[
        selectedOptionValue === 0
          ? ""
          : selectedOptionValue === 1
          ? 0
          : selectedOptionValue - 1
      ]?.question_id;

    let fieldIntIndex = parseInt(question, 10);
    if (value.length >= maxLength) {
      if (fieldIntIndex <= 30 && validatedValue) {
        const nextQuestionIndex = fieldIntIndex + 1;
        const nextFieldName = `field-${section}-${nextQuestionIndex}`;
        const currentInput = event.target;
        currentInput.classList.remove("focus-highlight");
        const nextInput = document.querySelector(
          `input[name=${nextFieldName}]`
        ) as HTMLInputElement;

        if (nextInput !== null) {
          nextInput.focus();
          nextInput.classList.add("focus-highlight");
        }
        if (event.target.value !== "0") {
          setSelectedOptions((prevSelectedOptions: any) => ({
            ...prevSelectedOptions,
            [selectedOptionKey]: selectedOptionValue,
          }));
          setCustomValues((prevCustomValues: any) => ({
            ...prevCustomValues,
            [selectedOptionKey]: selectedOptionValue,
          }));

          if (chapter_id !== 0) {
            setSelectedQuestions((prevSelectedQuestions) => {
              const selectedQuestionIndex = prevSelectedQuestions.findIndex(
                (question) =>
                  question?.chapter_id === chapter_id &&
                  question?.questionIndex === updatedQuestionIndex
              );
              if (selectedQuestionIndex === -1) {
                return [
                  ...prevSelectedQuestions,
                  {
                    chapter_id,
                    questionIndex: updatedQuestionIndex,
                    correct_answer: optionID,
                    option_id: optionID,
                    question_id: questionId === undefined ? 0 : questionId,
                  },
                ];
              } else {
                const updatedQuestions = [...prevSelectedQuestions];
                updatedQuestions[selectedQuestionIndex] = {
                  ...updatedQuestions[selectedQuestionIndex],
                  correct_answer: optionID,
                  option_id: optionID,
                };
                return updatedQuestions;
              }
            });
          }
        }
      } else if (!event.nativeEvent.data) {
        let nextQuestionIndex;
        if (event.key === "Backspace") {
          nextQuestionIndex = fieldIntIndex;
        } else {
          nextQuestionIndex = fieldIntIndex - 1;
        }
        const nextFieldName = `field-${section}-${nextQuestionIndex}`;
        const currentInput = event.target;
        currentInput.classList.remove("focus-highlight");
        const nextInput = document.querySelector(
          `input[name=${nextFieldName}]`
        ) as HTMLInputElement;

        if (nextInput !== null) {
          nextInput.focus();
          nextInput.classList.add("focus-highlight");
        }

        const isOptionSelected =
          parseInt(selectedOptions[selectedOptionKey]) === questionIndex + 1;

        if (isOptionSelected) {
          setSelectedOptions((prevSelectedOptions) => {
            const updatedOptions = { ...prevSelectedOptions };
            delete updatedOptions[selectedOptionKey];
            return updatedOptions;
          });
          setCustomValues((prevCustomValues) => {
            const updatedValues = { ...prevCustomValues };
            delete updatedValues[selectedOptionKey];
            return updatedValues;
          });
        } else {
          setSelectedOptions((prevSelectedOptions: any) => ({
            ...prevSelectedOptions,
            [selectedOptionKey]: selectedOptionValue + 1,
          }));
          setCustomValues((prevCustomValues) => ({
            ...prevCustomValues,
            [selectedOptionKey]: selectedOptionValue + 1,
          }));
        }
        setSelectedQuestions((prevSelectedQuestions) => {
          const selectedQuestionIndex = prevSelectedQuestions.findIndex(
            (question) =>
              question?.chapter_id === chapter_id &&
              question?.questionIndex === updatedQuestionIndex
          );
          if (selectedQuestionIndex === -1) {
            return [
              ...prevSelectedQuestions,
              {
                chapter_id,
                questionIndex: updatedQuestionIndex,
                correct_answer: "",
                option_id: optionID,
                question_id: questionId === undefined ? 0 : questionId,
              },
            ];
          } else {
            const updatedQuestions = [...prevSelectedQuestions];
            updatedQuestions[selectedQuestionIndex] = {
              ...updatedQuestions[selectedQuestionIndex],
              correct_answer: "",
            };
            return updatedQuestions;
          }
        });
      }
    }
  };
  return (
    <>
      {!resultPage ? (
        <>
          <Box sx={{ zIndex: 1 }}>
            <AssignmentDialog openAssignmentSuccess={assignmentModal} />
            <SessionDialog openSessionSuccess={sessionModal} />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 0,
            }}
          >
            <Box
              alignItems={{ xs: "flex-end", md: "center" }}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "5px",
                position: "relative",
              }}
            >
              <Box
                height={{ xs: "100%", md: "calc(100vh - 142px)" }}
                sx={mainBoxStyle}
              >
                {isLoading && (
                  <h2 style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                    Loading
                  </h2>
                )}
                <Box
                  sx={{
                    width: { xs: "calc(100vw - 20px)", md: "auto" },
                    overflow: "auto",
                    position: "relative",
                    display: "grid",
                    marginTop: { xs: "60px", md: "0" },
                    gridTemplateColumns: "1fr 1fr",
                    "@media (max-width: 900px)": {
                      gridTemplateColumns: "1fr",
                    },
                    gap: "8px 22px",
                    direction: "ltr",
                  }}
                >
                  <Box
                    display={{ xs: "none", md: "flex" }}
                    sx={{
                      gridColumn: 2,
                      gridRow: 1,
                      position: "absolute",
                      height: "100%",
                      flexDirection: "column",
                      gap: "5px",
                      zIndex: 0,
                      paddingLeft: "25px",
                    }}
                  >
                    {previewName ? (
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: 700,
                          fontSize: "24px",
                        }}
                      >
                        {previewName}
                      </Typography>
                    ) : (
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: 700,
                          fontSize: "24px",
                        }}
                      >
                        <FormattedMessage id="test-intro" />
                      </Typography>
                    )}

                    <p>תלמידים יקרים!</p>
                    <p>לפניכם טופס הזנה לתשובות המבחן שפתרתם. </p>
                    <p>
                      עליכם להזין את תשובותיכם בדיוק כפי שהן מופיעות בדף התשובות
                      המקורי של הבחינה.
                    </p>
                    <p>
                      ההזנה מתבצעת על-ידי הקלקה על מספר התשובה הרצוי באליפסה
                      המתאימה בעזרת העכבר או על-ידי את הקלדת מספר התשובה הרצוי
                      במלבן מתחת לשאלה המתאימה.
                    </p>
                    <p>
                      לאחר שסיימתם להקליד את התשובות במלואן לחצו על כפתור
                      "הגשה".
                    </p>
                    <p>
                      אם ברצונכם להזין מחדש את כל תשובותיכם, ניתן ללחוץ על כפתור
                      "איפוס" וכך לאפס את ההזנה הקודמת.
                    </p>
                    <p>בהצלחה!</p>
                  </Box>

                  {sections?.map((section: any, sectionIndex: any) => (
                    <React.Fragment key={`section_${sectionIndex}`}>
                      <Box
                        sx={{
                          "@media (max-width: 900px)": {
                            gridColumn: "1",
                            gridRow: "auto",
                          },
                          userSelect: "none",
                          gridColumn: section?.column,
                          gridRow: section?.row,
                          color: "#E74C3C",
                          fontWeight: "600",
                          direction: "ltr",
                          fontSize: "10px",
                          textTransform: "uppercase",

                          zIndex: 100,
                        }}
                      >
                        <span style={{}}>
                          {section?.title}{" "}
                          <span style={{ fontSize: "16px" }}>
                            {sectionIndex + 1}{" "}
                          </span>{" "}
                          {section?.sideTitle}{" "}
                        </span>
                        <Box
                          width={{ xs: "min-content", md: "auto" }}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            direction: "ltr",
                            border: "1px solid #EC7063",
                          }}
                        >
                          {section?.questions?.map(
                            (question: any, questionIndex: any) => {
                              const questionId = `${sectionIndex}_${questionIndex}`;

                              return (
                                <React.Fragment
                                  key={`question_${sectionIndex}_${questionIndex}`}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "5px",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      backgroundColor:
                                        questionIndex % 7 <= 3
                                          ? "#FDF2F1"
                                          : "white",
                                    }}
                                  >
                                    <Box
                                      fontSize={{ xs: "18px", md: "13px" }}
                                      marginX={{ xs: "1px", md: "2px" }}
                                      sx={{
                                        borderBottom: "1px solid #EC7063 ",
                                        textAlign: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <span> {questionIndex + 1}</span>
                                    </Box>
                                    {question?.option?.map(
                                      (option: any, optionIndex: any) => (
                                        <Box
                                          key={option.id + optionIndex}
                                          width={{ xs: "20px", md: "10px" }}
                                          height={{ xs: "20px", md: "15px" }}
                                          fontSize={{ xs: "15px", md: "12px" }}
                                          sx={{
                                            border:
                                              parseInt(
                                                selectedOptions[
                                                  `${sectionIndex}_${questionIndex}`
                                                ]
                                              ) !==
                                              optionIndex + 1
                                                ? "1px solid #EC7063"
                                                : "1px solid black",
                                            borderRadius: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                            background:
                                              parseInt(
                                                selectedOptions[
                                                  `${sectionIndex}_${questionIndex}`
                                                ]
                                              ) ===
                                              optionIndex + 1
                                                ? "black"
                                                : "none",
                                            color:
                                              parseInt(
                                                selectedOptions[
                                                  `${sectionIndex}_${questionIndex}`
                                                ]
                                              ) ===
                                              optionIndex + 1
                                                ? "black"
                                                : "#EC7063",
                                          }}
                                          onClick={() =>
                                            handleOptionClick(
                                              sectionIndex,
                                              questionIndex,
                                              optionIndex
                                            )
                                          }
                                        >
                                          <span>{optionIndex + 1}</span>
                                        </Box>
                                      )
                                    )}

                                    <input
                                      type="tel"
                                      name={`field-${sectionIndex}-${questionIndex}`}
                                      value={customValues[questionId] || ""}
                                      onChange={(event) =>
                                        handleCustomValueChange(
                                          event,
                                          sectionIndex,
                                          questionIndex
                                        )
                                      }
                                      style={{
                                        border: "1px solid #EC7063",
                                        display: "flex",
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        verticalAlign: "center",
                                        marginBottom: "2px",
                                        color: "#EC7063",
                                        width:
                                          window.innerWidth <= 768
                                            ? "20px"
                                            : "10px",
                                        height:
                                          window.innerWidth <= 768
                                            ? "20px"
                                            : "15px",
                                        fontSize:
                                          window.innerWidth <= 768
                                            ? "15px"
                                            : "12px",
                                        marginLeft: "1px",
                                        marginRight: "1px",
                                        outline: "none",
                                      }}
                                      onFocus={(event) => {
                                        event.target.style.borderColor =
                                          "#EC7063";
                                      }}
                                      onBlur={(event) => {
                                        event.target.style.borderColor =
                                          "#EC7063";
                                      }}
                                    />
                                  </Box>
                                </React.Fragment>
                              );
                            }
                          )}
                        </Box>
                      </Box>
                    </React.Fragment>
                  ))}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: { xs: "center", md: "space-around" },
                  height: "70px",
                  width: "210px",
                  alignItems: "center",
                  gap: "25px",
                  position: { xs: "absolute", md: "relative" },
                  transform: { xs: "translateX(-50%)", md: "none" },
                  bottom: { xs: "-70px", md: "auto" },
                  left: { xs: "50%", md: "auto" },
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "10px",
                    padding: "9px 22px",
                    fontSize: "18px",
                    fontWeight: 500,
                    height: "40px",
                    width: "88px",
                  }}
                  onClick={handleResetExam}
                >
                  {" "}
                  איפוס{" "}
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    padding: "9px 22px",

                    fontSize: "18px",
                    fontWeight: 500,
                    height: "40px",
                    width: "88px",
                  }}
                  onClick={() => handleAnswerSubmit()}
                >
                  <FormattedMessage id="submit-answers" />
                </Button>
              </Box>
            </Box>

            {isDialogOpen && (
              <Dialog
                open={isDialogOpen}
                onClose={handleClosePopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  sx: { borderRadius: "30px" },
                }}
              >
                <Box
                  style={{
                    padding: "25px",
                    paddingBottom: "35px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Button style={{ justifyContent: "end" }}>
                    <Close onClick={handleClosePopup} />
                  </Button>
                  <DialogContent style={{ padding: 0 }}>
                    <DialogContentText id="alert-dialog-description">
                      {/* <Typography
                      style={{
                        color: "#222529",
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: "24px",
                      }}
                    >
                      <FormattedMessage id="no-answer-popup-title" />
                    </Typography> */}
                      <Typography
                        style={{
                          color: "#222529",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "24px",
                        }}
                      >
                        <FormattedMessage id="chapterModal" />
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "block",
                      paddingTop: "20px",
                      marginInline: "auto",
                    }}
                  >
                    <Stack direction="row" justifyContent="center" width="100%">
                      <Button
                        onClick={handleClosePopup}
                        autoFocus
                        variant="contained"
                        style={{
                          border: "1px solid #7A49B9",
                          marginInline: "5px",
                        }}
                      >
                        <FormattedMessage id="no" />
                      </Button>
                      <Button
                        onClick={nextPage}
                        style={{
                          border: "1px solid #7A49B9",
                          marginInline: "5px",
                        }}
                      >
                        <FormattedMessage id="yes" />
                      </Button>
                    </Stack>
                  </DialogActions>
                </Box>
              </Dialog>
            )}
            {isDialogReset && (
              <Dialog
                open={isDialogReset}
                onClose={handleReset}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  sx: { borderRadius: "30px" },
                }}
              >
                <Box
                  style={{
                    padding: "25px",
                    paddingBottom: "35px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Button style={{ justifyContent: "end" }}>
                    <Close onClick={handleReset} />
                  </Button>
                  <DialogContent style={{ padding: 0 }}>
                    <DialogContentText id="alert-dialog-description">
                      <Typography
                        style={{
                          color: "#222529",
                          textAlign: "center",
                          fontWeight: "700",
                          fontSize: "24px",
                        }}
                      >
                        <FormattedMessage id="reset-title" />
                      </Typography>
                      <Typography
                        style={{
                          color: "#222529",
                          textAlign: "center",
                          fontSize: "24px",
                        }}
                      >
                        <FormattedMessage id="reset-text" />
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    style={{
                      display: "block",
                      paddingTop: "20px",
                      marginInline: "auto",
                    }}
                  >
                    <Stack direction="row" justifyContent="center" width="100%">
                      <Button
                        onClick={handleReset}
                        autoFocus
                        variant="contained"
                        style={{
                          border: "1px solid #7A49B9",
                          marginInline: "5px",
                        }}
                      >
                        <FormattedMessage id="no" />
                      </Button>
                      <Button
                        onClick={handleResetClick}
                        style={{
                          border: "1px solid #7A49B9",
                          marginInline: "5px",
                        }}
                      >
                        <FormattedMessage id="yes" />
                      </Button>
                    </Stack>
                  </DialogActions>
                </Box>
              </Dialog>
            )}
          </Box>
        </>
      ) : !checkResultPage && setPrincipleAccordionNavigationFlag ? (
        <Box
          sx={{
            marginTop: { xs: "35px", md: "50px" },
          }}
        >
          <Box marginRight={{ xs: "15%", md: "15%" }}>
            {/* <Typography variant="h3" sx={{ fontWeight: 700, fontSize: "24px" }}>
              {exam_data[0]?.preview_name
                ? exam_data[0].preview_name
                : "מבחן פתיחה"}
            </Typography> */}
            {previewName ? (
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 700,
                  fontSize: "24px",
                }}
              >
                {previewName}
              </Typography>
            ) : (
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 700,
                  fontSize: "24px",
                }}
              >
                <FormattedMessage id="test-intro" />
              </Typography>
            )}
          </Box>

          <Box
            marginRight={{ xs: "5%", md: "15%" }}
            sx={{ marginTop: { xs: "20px", md: "0px" } }}
          >
            <Tabs
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#7A49B9",
                },
              }}
              value={currentTab}
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              {pages.map((page: any) => (
                <Tab
                  color="error"
                  label={<FormattedMessage id={page} />}
                  sx={{
                    color: "#878E95",
                    "&.MuiTab-root.Mui-selected": {
                      color: "#7A49B9",
                      fontWeight: 600,
                    },
                    "&.MuiTab-root:not(.Mui-selected)": {
                      borderBottom: "1px solid #878E95",
                    },
                  }}
                />
              ))}
            </Tabs>
          </Box>

          <Box
            minHeight={{ md: "calc(100vh - 132px)" }}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Box
              sx={{
                minHeight: "auto",
                width: { xs: "95%", md: "70%" },
                maxHeight: { xs: "65vh", md: "58vh" },
                overflowY: "auto",
                marginTop: "40px",

                borderRadius: "20px",
                marginX: "auto",
              }}
            >
              {currentTab == 0 && (
                <Box
                  sx={{
                    borderRadius: "20px",
                    border: "1px solid(--black-black-40, #DFE2E5)",
                    boxShadow: "0px 5px 16px 0px rgba(8, 15, 52, 0.05)",
                  }}
                >
                  <AccordionForChapterExam assignmentId={assignment_id} />
                </Box>
              )}
              {currentTab == 1 && (
                <AccordionForChapterPrinciple assignmentId={assignment_id} />
              )}
            </Box>

            <Box>
              <Button
                sx={{
                  border: "1px solid #7A49B9",
                  borderRadius: "10px",
                  marginTop: { xs: "30px", md: "0px" },
                  padding: { xs: "5px px", md: "9px 22px" },
                  backgroundColor: "#7A49B9",
                  color: "#FFFFFF",
                  fontSize: { xs: "14px", md: "18px" },
                  fontWeight: 500,
                }}
                variant="contained"
                onClick={() => handleCheckResultExam()}
              >
                פתרונות מלאים{" "}
              </Button>{" "}
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              backgroundColor: "#F8F9FA",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              overflow: { xs: "hidden", md: "auto" },
            }}
          >
            <Box
              sx={{
                height: "auto",
                backgroundColor: "#E8EBED",
                display: { xs: "none", md: "block" },
              }}
            >
              {ChapterWiseQuestion &&
              selectedChapter != 0 &&
              ChapterWiseQuestion.length > 0 ? (
                <>
                  <QuestionSideBar
                    question_id={ChapterWiseQuestion[0][activeQuestion]}
                  />
                </>
              ) : (
                <></>
              )}
            </Box>
            <Box
              className={`${
                activeQuestion == 0 ? "question-option-mobile-4" : ""
              }`}
              sx={{
                backgroundColor: "#ffffff",
                boxShadow: "-4px 4px 4px 0px rgba(151, 151, 151, 0.25)",
                borderRadius: "0px 30px 30px 0px",
                height: "42px",
                width: "52px",
                position: "absolute",
                top: "78px",
                left: "0",
                textAlign: "center",
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "20%",
                zIndex: "1",
              }}
            >
              <IconButton onClick={handleIconCClick}>
                <EditIconCollaps />
              </IconButton>
            </Box>
            <Modal
              open={isModalOpen}
              onClose={handleCloseModal}
              sx={{
                position: "fixed",
                top: "78px",
                right: "auto",
                left: "0 !important",
                height: "431px",
                width: "350px",
                backgroundColor: "#E8EBED",
                border: "1px solid #DFE2E5",
                borderRadius: "0 30px 30px 0",
                overflow: "hidden",
              }}
            >
              <Box
                className="modal-content"
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  backgroundColor: "#E8EBED",
                  borderRadius: "0 30px 30px 0",
                  overflow: "hidden",
                }}
              >
                <IconButton
                  onClick={handleIconCClick}
                  style={{
                    position: "absolute",
                    left: "10px",
                    top: "10px",
                  }}
                >
                  <EditIconCollapsModel />
                </IconButton>
                <ArrowRight
                  onClick={handleCloseModal}
                  style={{
                    position: "relative",
                    right: "10px",
                    top: "15px",
                  }}
                />

                {ChapterWiseQuestion &&
                selectedChapter != 0 &&
                ChapterWiseQuestion.length > 0 ? (
                  <>
                    <QuestionSideBar
                      question_id={ChapterWiseQuestion[0][activeQuestion]}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Modal>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {isLoading && (
                <h2 style={{ paddingRight: "15px", paddingLeft: "15px" }}>
                  Loading
                </h2>
              )}

              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    margin: "auto",
                    zIndex: "0",
                    padding: {
                      md: "35px 200px 0px 200px ",
                      xs: "20px 20px 0px 20px",
                    },
                  }}
                >
                  {" "}
                  {ChapterWiseQuestion &&
                  selectedChapter != 0 &&
                  ChapterWiseQuestion.length > 0
                    ? ChapterWiseQuestion[0].map(
                        (item: any, index: any) =>
                          activeQuestion === index && (
                            <>
                              <div>
                                <Typography
                                  className={`s `}
                                  marginRight={{ xs: "40px", md: "0px" }}
                                  marginBottom="10px"
                                  sx={{
                                    color: "#878E95",
                                    fontWeight: 100,
                                    textAlign: "right",
                                  }}
                                >
                                  <FormattedMessage
                                    id="questionInfo"
                                    values={{
                                      totalQuestions:
                                        fetchAllSubmittedQuestionAnswer?.length,
                                      currentQuestion,
                                    }}
                                  />
                                </Typography>
                              </div>

                              <Grid
                                mt={{ xs: "30px", md: "20px" }}
                                sx={{
                                  borderTop: "1px solid #E6E6E6",
                                  padding: "15px",
                                  paddingTop: { xs: "60px", md: "15px" },
                                }}
                              >
                                <QuestionAttemptAndCheck
                                  options={item.option}
                                  handleSelection={handleSelectOption}
                                  horizontal={1}
                                />
                                <Box
                                  marginTop="20px"
                                  style={{ backgroundColor: "#E8EBED" }}
                                >
                                  <DescriptionBox
                                    key={index}
                                    description={item.solving_explanation}
                                    file_path={item?.explanation_image}
                                  />
                                </Box>
                              </Grid>
                            </>
                          )
                      )
                    : ""}
                </Box>
              </div>

              {fetchAllSubmittedQuestionAnswer?.length > 0 ? (
                <Box sx={{ minHeight: "70px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#FFFFFF",
                      height: "70px",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={
                        activeQuestion ==
                        fetchAllSubmittedQuestionAnswer.length - 1
                      }
                      sx={{
                        backgroundColor: "7A49B9",
                        my: "15px",
                        width: "152px",
                        height: "40px",
                        padding: "9px 22px",
                        borderRadius: "8px",
                      }}
                      onClick={() => handleNextCheckAnswer()}
                    >
                      <FormattedMessage id="next-question" />
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #7A49B9",
                        color: "#7A49B9",
                        my: "15px",
                        marginRight: "10px",
                        height: "40px",
                        padding: "10px 30px",
                        borderRadius: "8px",
                      }}
                      onClick={
                        isResearch||is_student_exam|| product_exam? handleNavigateResearch : handleComplete
                      }
                    >
                      <FormattedMessage id="done-researching" />
                    </Button>
                  </div>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default ChapterExam;
